.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: firaSans;
  src: url(assets/fonts/FiraSans-Light.ttf);
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-weight: normal;
}
*{
  font-size: 2vh;
   font-family: firaSans !important;
}

@media (max-width: 426px) {
  *{
    font-size: 1rem;
    font-family: firaSans !important;
  }
 }



a {
  color: inherit;
  text-decoration: none;
}
input:focus,
textarea:focus,
select:focus {
  outline: none;
}

/* width */
::-webkit-scrollbar {
  width: 9px;
  height:9px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 3px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}